<template>
  <section
      id="summary-stat"
      data-app
  >
    <b-row
        v-if="hasProjects"
        class="match-height"
    >
      <transition
          mode="out-in"
          name="fade"
      >
        <b-col
            v-show="emptyStats !== 'NO_STATS'"
            cols="12"
        >
          <!--          <flat-pickr
                      v-model="rangeData"
                      :config="dataPickerConfig"
                      :disabled="isLoading"
                      class="form-control flat-picker bg-transparent border-0 shadow-none"
                      placeholder="YYYY-MM-DD"
                      @on-close="changeDatesRange"
                    />-->
          <month-picker-input style="z-index: 10;" lang="ru" :default-month="rangeData[1].getMonth() + 1"
                              @change="showDate"
          ></month-picker-input>
          <div>
            <b-table
                ref="table"
                :data="data"
                :loading="isLoading"
                :per-page="perPage.value"
                :show-detail-icon="showDetailIcon"
                :sticky-header="stickyHeaders"
                class="mt-2"
                custom-detail-row
                detail-key="id"
                detailed
                height="100vh"
                hoverable
            >
              <!-- project -->
              <b-table-column
                  v-slot="props"
                  :label="columnsVisible['project'].title"
                  field="project"
                  sortable
                  width="300"
              >
                <template v-if="showDetailIcon">
                  {{ props.row.project }}
                </template>
                <template v-else>
                  <a @click="toggle(props.row)">
                    {{ props.row.project }}
                  </a>
                </template>
              </b-table-column>

              <!-- system -->
              <b-table-column
                  v-slot="props"
                  :label="columnsVisible['system'].title"
                  field="system"
                  sortable
                  width="300"
              >
                {{ props.row.system }}
              </b-table-column>

              <!-- consumption fact-->
              <b-table-column
                  v-slot="props"
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'consumptionFact')"
                  :label="columnsVisible['consumptionFact'].title"
                  :visible="columnsVisible['consumptionFact'].display"
                  centered
                  field="consumptionFact"
                  sortable
              >
                {{ columnValueFormat(props.row.consumptionFact) }}
              </b-table-column>

              <!-- consumption plan-->
              <b-table-column
                  v-slot="props"
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'consumptionPlan')"
                  :label="columnsVisible['consumptionPlan'].title"
                  :visible="columnsVisible['consumptionPlan'].display"
                  centered
                  field="consumptionPlan"
                  sortable
              >
                {{ columnValueFormat(props.row.consumptionPlan) }}
              </b-table-column>

              <!-- consumption completion-->
              <b-table-column
                  v-slot="props"
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'consumptionCompletion')"
                  :label="columnsVisible['consumptionCompletion'].title"
                  :visible="columnsVisible['consumptionCompletion'].display"
                  centered
                  field="consumptionCompletion"
                  sortable
              >
                {{ columnValueFormat(props.row.consumptionCompletion) }} %
              </b-table-column>

              <!-- consumption trend-->
              <b-table-column
                  v-slot="props"
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'consumptionTrend')"
                  :label="columnsVisible['consumptionTrend'].title"
                  :visible="columnsVisible['consumptionTrend'].display"
                  centered
                  :td-attrs="changeColumnColor"
                  field="consumptionTrend"
                  sortable
              >
                {{ columnValueFormat(props.row.consumptionTrend) }}
              </b-table-column>

              <!-- kpi1 fact-->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'kpi1Fact')"
                  :label="columnsVisible['kpi1Fact'].title"
                  :visible="columnsVisible['kpi1Fact'].display"
                  centered
                  field="kpi1Fact"
                  sortable
              >
                <template v-slot:header="{ column }">
                  <b-tooltip :title="columnsVisible['kpi1Fact'].tooltip">
                    {{ column.label }}
                  </b-tooltip>
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.kpi1Fact) }}
                </template>
              </b-table-column>

              <!-- kpi1 plan-->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'kpi1Plan')"
                  :label="columnsVisible['kpi1Plan'].title"
                  :visible="columnsVisible['kpi1Plan'].display"
                  centered
                  field="kpi1Plan"
                  sortable
              >
                <template v-slot:header="{ column }">
                  <b-tooltip :title="columnsVisible['kpi1Plan'].tooltip">
                    {{ column.label }}
                  </b-tooltip>
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.kpi1Plan) }}
                </template>
              </b-table-column>

              <!-- kpi1 divergence-->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'kpi1Divergence')"
                  :label="columnsVisible['kpi1Divergence'].title"
                  :visible="columnsVisible['kpi1Divergence'].display"
                  centered
                  :td-attrs="changeColumnColor"
                  field="kpi1Divergence"
                  sortable
              >
                <template v-slot:header="{ column }">
                  <b-tooltip :title="columnsVisible['kpi1Divergence'].tooltip">
                    {{ column.label }}
                  </b-tooltip>
                </template>
                <template v-slot="props">
                  {{
                    props.row.kpi1Divergence === '-' ? props.row.kpi1Divergence : `${columnValueFormat(props.row.kpi1Divergence)} %`
                  }}
                </template>
              </b-table-column>

              <!-- kpi1 trend-->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'kpi1Trend')"
                  :label="columnsVisible['kpi1Trend'].title"
                  :visible="columnsVisible['kpi1Trend'].display"
                  centered
                  field="kpi1Trend"
                  sortable
              >
                <template v-slot:header="{ column }">
                  <b-tooltip :title="columnsVisible['kpi1Trend'].tooltip">
                    {{ column.label }}
                  </b-tooltip>
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.kpi1Trend) }}
                </template>
              </b-table-column>

              <!-- kpi2 fact-->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'kpi2Fact')"
                  :label="columnsVisible['kpi2Fact'].title"
                  :visible="columnsVisible['kpi2Fact'].display"
                  centered
                  field="kpi2Fact"
                  sortable
              >
                <template v-slot:header="{ column }">
                  <b-tooltip :title="columnsVisible['kpi2Fact'].tooltip">
                    {{ column.label }}
                  </b-tooltip>
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.kpi2Fact) }}
                </template>
              </b-table-column>

              <!-- kpi2 plan-->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'kpi2Plan')"
                  :label="columnsVisible['kpi2Plan'].title"
                  :visible="columnsVisible['kpi2Plan'].display"
                  centered
                  field="kpi2Plan"
                  sortable
              >
                <template v-slot:header="{ column }">
                  <b-tooltip :title="columnsVisible['kpi2Plan'].tooltip">
                    {{ column.label }}
                  </b-tooltip>
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.kpi2Plan) }}
                </template>
              </b-table-column>

              <!-- kpi2 completion-->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'kpi2Completion')"
                  :label="columnsVisible['kpi2Completion'].title"
                  :visible="columnsVisible['kpi2Completion'].display"
                  centered
                  field="kpi2Completion"
                  sortable
              >
                <template v-slot:header="{ column }">
                  <b-tooltip :title="columnsVisible['kpi2Completion'].tooltip">
                    {{ column.label }}
                  </b-tooltip>
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.kpi2Completion) }} %
                </template>
              </b-table-column>

              <!-- kpi2 trend-->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'kpi2Trend')"
                  :label="columnsVisible['kpi2Trend'].title"
                  :visible="columnsVisible['kpi2Trend'].display"
                  centered
                  :td-attrs="changeColumnColor"
                  field="kpi2Trend"
                  sortable
              >
                <template v-slot:header="{ column }">
                  <b-tooltip :title="columnsVisible['kpi2Trend'].tooltip">
                    {{ column.label }}
                  </b-tooltip>
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.kpi2Trend) }}
                </template>
              </b-table-column>

              <template
                  slot="detail"
                  slot-scope="props"
              >

                <tr
                    v-for="item in props.row.items"
                    :key="item.id"
                >
                  <td v-if="showDetailIcon"/>
                  <td/>
                  <td>
                    &nbsp;&nbsp;&nbsp;&nbsp;{{ item.system }}
                  </td>

                  <!-- consumption -->
                  <td
                      v-show="columnsVisible['consumptionFact'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.consumptionFact) }}
                  </td>
                  <td
                      v-show="columnsVisible['consumptionPlan'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.consumptionPlan) }}
                  </td>
                  <td
                      v-show="columnsVisible['consumptionCompletion'].display"
                      class="has-text-centered"
                  >
                    {{
                      item.consumptionCompletion === '-' ? item.consumptionCompletion : `${columnValueFormat(item.consumptionCompletion)} %`
                    }}
                  </td>
                  <td
                      v-show="columnsVisible['consumptionTrend'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.consumptionTrend) }}
                  </td>

                  <!-- kpi1 -->
                  <td
                      v-show="columnsVisible['kpi1Fact'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.kpi1Fact) }}
                  </td>
                  <td
                      v-show="columnsVisible['kpi1Plan'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.kpi1Plan) }}
                  </td>
                  <td
                      v-show="columnsVisible['kpi1Divergence'].display"
                      class="has-text-centered"
                  >
                    {{
                      item.kpi1Divergence === '-' ? item.kpi1Divergence : `${columnValueFormat(item.kpi1Divergence)} %`
                    }}
                  </td>
                  <td
                      v-show="columnsVisible['kpi1Trend'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.kpi1Trend) }}
                  </td>

                  <!-- kpi2 -->
                  <td
                      v-show="columnsVisible['kpi2Fact'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.kpi2Fact) }}
                  </td>
                  <td
                      v-show="columnsVisible['kpi2Plan'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.kpi2Plan) }}
                  </td>
                  <td
                      v-show="columnsVisible['kpi2Completion'].display"
                      class="has-text-centered"
                  >
                    {{
                      item.kpi2Completion === '-' ? item.kpi2Completion : `${columnValueFormat(item.kpi2Completion)} %`
                    }}
                  </td>
                  <td
                      v-show="columnsVisible['kpi2Trend'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.kpi2Trend) }}
                  </td>
                </tr>
              </template>

            </b-table>
          </div>
        </b-col>
      </transition>

      <transition
          mode="out-in"
          name="fade"
      >
        <b-col
            v-show="emptyStats === 'NO_STATS'"
            cols="12"
        >
          <div class="misc-inner p-2 p-sm-3">
            <div class="w-100 text-center">
              <h2 class="mb-1">
                На проекте нет статистики
              </h2>
              <p class="mb-3">
                Подключите интеграции к вашему проекту
              </p>

              <b-img
                  :src="downImg"
                  alt=""
                  fluid
              />
            </div>
          </div>
        </b-col>
      </transition>
    </b-row>
    <b-row
        v-if="!hasProjects"
        class="match-height"
    >

      <b-col cols="12">
        <div class="misc-inner p-2 p-sm-3">
          <div class="w-100 text-center">
            <h2 class="mb-1">
              У вас нет созданных проектов
            </h2>
            <h4 class="mb-3">
              <b-link @click="tourStartAnalytics">
                Хотите перейти к созданию проекта?
              </b-link>
            </h4>
            <b-img
                :src="downImg"
                alt=""
                fluid
            />
          </div>
        </div>
      </b-col>
    </b-row>
    <app-tour
        :steps="steps"
        name="test"
    />
  </section>
</template>
<script>
import _ from 'lodash'
import { kFormatter } from '@core/utils/filter'
import AppTour from '@core/components/app-tour/AppTour.vue'
import {
  BBadge,
  BButton,
  BButtonGroup,
  BCard,
  BCardBody,
  BCardHeader,
  BCardSubTitle,
  BCardTitle,
  BCol,
  BFormGroup,
  BFormRow,
  BImg,
  BLink,
  BOverlay,
  BRow,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

// eslint-disable-next-line import/extensions
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import vSelect from 'vue-select'
import OpenIndicator from '@core/components/vue-select/OpenIndicator.vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import { MonthPickerInput } from 'vue-month-picker'
import apexChatData from '@/views/charts-and-maps/charts/apex-chart/apexChartData'

const dateNow = new Date()
const dateNowPrev = new Date(new Date().setMonth(new Date().getMonth() - 1))
// const dateNow = new Date(2022, 11, 31)
// const dateNowPrev = new Date(2022, 11, 1)
dateNow.setHours(0, 0, 0, 0)
dateNowPrev.setHours(0, 0, 0, 0)
// eslint-disable-next-line no-extend-native
Date.prototype.yyyymmdd = function () {
  const mm = this.getMonth() + 1
  const dd = this.getDate()

  // eslint-disable-next-line radix
  return [this.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd,
  ].join('')
}

const chartColors = {
  column: {
    series1: '#826af9',
    series2: '#d2b0ff',
    bg: '#f8d3ff',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  donut: {
    series1: '#ffe700',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
  area: {
    series10: '#a9390c',
    series9: '#316b6b',
    series8: '#e26572',
    series7: '#e369b6',
    series6: '#5dd2db',
    series5: '#7253d0',
    series4: '#4f82cf',
    series3: '#8BC554',
    series2: '#FECC35',
    series1: '#FC4526',
  },
}
vSelect.props.components.default = () => ({ OpenIndicator })

export default {
  components: {
    BCard,
    MonthPickerInput,
    BFormCheckboxGroup,
    AppTour,
    vSelect,
    VueApexCharts,
    BLink,
    BCardHeader,
    BCardBody,
    BButton,
    BCardTitle,
    BButtonGroup,
    BFormRow,
    BFormGroup,
    BImg,
    BCardSubTitle,
    flatPickr,
    BOverlay,
    BRow,
    BBadge,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      steps: [
        {
          target: '#__BVID__48__BV_toggle_',
          header: {
            title: 'Создайте проект',
          },
          content: 'Кликните и перейдите в раздел "Проекты"',
        },
      ],
      stickyHeaders: true,
      data: [],
      emptyStats: null,
      perPage: {
        value: 30,
        text: '30 на странице',
      },
      downImg: require('@/assets/images/pages/coming-soon.svg'),
      campaign: [],
      campaignOptions: [],
      optionsPerPages: [
        {
          value: 10,
          text: '10 на странице',
        },
        {
          value: 30,
          text: '30 на странице',
        },
        {
          value: 50,
          text: '50 на странице',
        },
        {
          value: 100,
          text: '50 на странице',
        },
        {
          value: 250,
          text: '250 на странице',
        },
        {
          value: 500,
          text: '500 на странице',
        },
      ],
      useTransition: false,
      isLoading: false,
      availableKpi1Options: [
        'Маржинальная прибыль (аналитика)',
        'Маржинальная прибыль (CRM)',
        'Стоимость заказов (аналитика)',
        'Стоимость заказов (CRM)',
        'Roi (аналитика)',
        'Roi (CRM)',
      ],
      availableKpi2Options: [
        'Выручка (аналитика)',
        'Выручка (CRM)',
        'Кол-во заказов (аналитика)',
        'Кол-во заказов (CRM)',
        'Показы',
        'Клики',
      ],
      columnsVisible: {
        project: {
          title: 'Проект',
          display: true,
          type: 'project',
        },
        system: {
          title: 'Система',
          display: true,
          // subheading: 'Всего:',
          type: 'system',
        },
        consumptionFact: {
          title: 'Расход (факт)',
          display: true,
          type: 'Трафик',
        },
        consumptionPlan: {
          title: 'Расход (kpi)',
          display: true,
          type: 'Трафик',
        },
        consumptionCompletion: {
          title: 'Выполнение (Расход)',
          display: true,
          type: 'Трафик',
        },
        consumptionTrend: {
          title: 'Расход (тренд)',
          display: true,
          type: 'Трафик',
        },
        kpi1Fact: {
          title: 'Kpi1 (факт)',
          display: false,
          type: 'Трафик',
          tooltip: '',
        },
        kpi1Plan: {
          title: 'Kpi1 (план)',
          display: false,
          type: 'Трафик',
          tooltip: '',
        },
        kpi1Divergence: {
          title: 'Выполнение (Kpi1)',
          display: false,
          type: 'Трафик',
          tooltip: '',
        },
        kpi1Trend: {
          title: 'Kpi1 (тренд)',
          display: false,
          type: 'Трафик',
          tooltip: '',
        },
        kpi2Fact: {
          title: 'Kpi2 (факт)',
          display: false,
          type: 'Трафик',
          tooltip: '',
        },
        kpi2Plan: {
          title: 'Kpi2 (план)',
          display: false,
          type: 'Трафик',
          tooltip: '',
        },
        kpi2Completion: {
          title: 'Выполнение (Kpi2)',
          display: false,
          type: 'Трафик',
          tooltip: '',
        },
        kpi2Trend: {
          title: 'Kpi2 (тренд)',
          display: false,
          type: 'Трафик',
          tooltip: '',
        },
      },
      showDetailIcon: true,
      items: [],
      rangeData: [dateNowPrev, dateNow],
      changedRangeData: [],
      apexChatData,
      saveStatisticData: [],
      isShowColumnsRow: false,
      dataPickerConfig: {
        mode: 'range',
        locale: Russian,
        dateFormat: 'Y-m',
      },
      plugins: [
        // eslint-disable-next-line new-cap
        new monthSelectPlugin({
          shorthand: true, // defaults to false
          dateFormat: 'm.y', // defaults to "F Y"
          altFormat: 'F Y', // defaults to "F Y"
          theme: 'dark', // defaults to "light"
        }),
      ],
    }
  },
  computed: {
    localStorageActiveProject() {
      return localStorage.getItem('active_project_id')
    },
    groupedColumns() {
      function getPredicate() {
        return v => v.type !== 'campaign'
      }

      const columns = _.groupBy(_.filter(this.columnsVisible,
          getPredicate()), 'type')
      return columns
    },
    isChanged() {
      return this.$store.state.project.isChanged
    },
    hasProjects() {
      return this.$store.state.project.projects.length !== 0
    },
    tableData() {
      return this.$store.state.statistic.summaryTableData
    },
  },
  mounted() {
    /* if (this.hasProjects) {
      this.loadStats(this.rangeData)
    } */
    //this.loadStats(this.rangeData)
  },
  methods: {
    changeColumnColor(row, column) {
      switch (column.field) {
        case 'consumptionTrend':
          return this.getColorClass(
              (Math.abs(row.consumptionTrend) / row.consumptionPlan - 1) * 100, false,
          )
        case 'kpi1Divergence':
          return this.getColorClass(row.kpi1Divergence - 100)
        case 'kpi2Trend':
          return this.getColorClass((row.kpi2Trend / row.kpi2Plan - 1) * 100)
        default:
          return { class: 'is-danger' }
      }
    },

    getColorClass(diff, isKpi = true) {
      const lightStyle = {
        success: {
          class: 'is-light',
          style: {
            backgroundColor: '#d9ead3',
            color: '#257953'
          },
        },
        warning: {
          class: 'is-light',
          style: {
            backgroundColor: '#fff2cc',
            color: '#946c00'
          },
        },
        danger: {
          class: 'is-light',
          style: {
            backgroundColor: '#f9cb9c',
            color: '#cc0f35'
          },
        },
      }
      if (isKpi) {
        if (diff >= 20) return { class: 'is-success' }        // Зеленый
        if (diff >= 8) return lightStyle.success              // Легкий зеленый    if (diff >= -5) return lightStyle.warning             // Легкий желтый
        if (diff >= -10) return { class: 'is-warning' }       // Желтый    if (diff >= -17) return lightStyle.danger             // Легкий красный
        return { class: 'is-danger' }                         // Красный  }
        if (diff >= 22 || diff <= -22) return { class: 'is-danger' }      // Очень красный
        if (diff >= 18 || diff <= -18) return lightStyle.danger            // Красный  if (diff >= 14 || diff <= -14) return { class: 'is-warning' }      // Оранжевый
        if (diff >= 10 || diff <= -10) return lightStyle.warning           // Желтый  if (diff >= 5 || diff <= -5) return lightStyle.success             // Легкий зеленый
        return { class: 'is-success' }
      }
    },
    kFormatter,
    showDate(date) {
      const dateFormatted = [date.from, date.to]

      //console.log(date.to, 'date.to')

      /*   // Функция для уменьшения даты на один день
        function subtractOneDay(formDate) {
          const newDate = new Date(formDate)
          newDate.setDate(newDate.getDate() - 1)
          return newDate
        }

        // Применение функции к date.to
        const newDateFormatted = {
          from: date.from,
          to: subtractOneDay(date.to),
        } */

      this.loadStats(dateFormatted)
    },
    testSignalr() {
      // logout api
      this.$http.get('logger/signal')
          // eslint-disable-next-line no-unused-vars
          .then(response => {
          })
    },
    tourStartAnalytics() {
      this.$tours.test.start()
    },
    columnValueFormat(val) {
      //console.log(val, 'val')
      if (val === null || val === '-' || val === undefined || isNaN(val)) {
        return '-'
      }

      if (Math.abs(val) < 0.05 || val === 0) {
        return 0
      }

      if (Math.round(+val) !== +val) {
        return val.toLocaleString(undefined, {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        })
      }

      return (+val).toLocaleString()
    },
    customSort(a, b, isAsc, val) {
      if (!isAsc) {
        a.items.sort((ai, bi) => Number(bi[val]) - Number(ai[val]))
        b.items.sort((ai, bi) => Number(bi[val]) - Number(ai[val]))
        return b[val] - a[val]
      }
      a.items.sort((ai, bi) => Number(ai[val]) - Number(bi[val]))
      b.items.sort((ai, bi) => Number(ai[val]) - Number(bi[val]))
      return a[val] - b[val]
    },
    loadStats(dateRange, isChanged) {
      if (this.changedRangeData[0] && this.changedRangeData[0]
          && dateRange[0] && dateRange[0]
          && this.changedRangeData[0].getTime() === dateRange[0].getTime()
          && this.changedRangeData[1].getTime() === dateRange[1].getTime()) {
        return
      }

      this.isLoading = true
      this.emptyStats = null
      this.data = []
      this.saveStatisticData = []
      this.campaignOptions = []
      this.changedRangeData = dateRange

      if (dateRange != null) {
        const startDate = dateRange[0]
        const endDate = dateRange[1]

        if (startDate != null && endDate != null) {
          const filterTableData = {
            startDate: (+startDate.yyyymmdd()),
            endDate: (+endDate.yyyymmdd()),
          }

          const isDefaultRange = (+startDate.yyyymmdd()) === (+dateNowPrev.yyyymmdd())
              && (+endDate.yyyymmdd()) === (+dateNow.yyyymmdd())

          const isNeedBuild = this.tableData != null
              && isDefaultRange

          if (!isChanged && isNeedBuild) {
            this.calculateTableData(this.tableData)
          } else {
            this.buildTable(filterTableData, isDefaultRange)
          }

          this.$store.commit('project/UPDATE_LOADING_PROJECT', false)
        }
      }
    },
    changeDatesRange(selectedDates) {
      this.loadStats(selectedDates)
    },
    calculateTableData(dataForParse) {
      // table parsed
      let increment = 0
      // let selectedKpiIncrement = 0
      const emptySymbol = null
      let emptySymbolConsumption = emptySymbol
      let emptySymbolKpi1 = emptySymbol
      let emptySymbolKpi2 = emptySymbol
      // const availableKpis = ['Показы', 'Клики', 'Средняя стоимость клика',
      //   'Ctr', 'Выручка (аналитика)', 'Маржинальная прибыль (аналитика)',
      //   'Кол-во заказов (аналитика)', 'Стоимость заказов (аналитика)',
      //   'Cr (аналитика)', 'Выручка (CRM)', 'Маржинальная прибыль (CRM)',
      //   'Кол-во заказов (CRM)', 'Стоимость заказов (CRM)',
      //   'Cr (CRM)', 'Roi (CRM)']

      Object.keys(dataForParse)
          .forEach(key => {
            const statItem = dataForParse[key]

            const stat = {
              id: 0,
              project: key,
              system: '',

              consumptionFact: emptySymbolConsumption,
              consumptionPlan: emptySymbolConsumption,
              consumptionCompletion: emptySymbolConsumption,
              consumptionTrend: emptySymbolConsumption,

              kpi1Fact: emptySymbolKpi1,
              kpi1Plan: emptySymbolKpi1,
              kpi1Divergence: emptySymbolKpi1,
              kpi1Trend: emptySymbolKpi1,

              kpi2Fact: emptySymbolKpi2,
              kpi2Plan: emptySymbolKpi2,
              kpi2Completion: emptySymbolKpi2,
              kpi2Trend: emptySymbolKpi2,

              items: [],
            }

            const subObjBrend = {
              // eslint-disable-next-line no-plusplus
              id: `stat-brend-${increment}`,
              system: 'Бренд',
              consumptionFact: emptySymbolConsumption,
              consumptionPlan: emptySymbolConsumption,
              consumptionCompletion: emptySymbolConsumption,
              consumptionTrend: emptySymbolConsumption,

              kpi1Fact: emptySymbolKpi1,
              kpi1Plan: emptySymbolKpi1,
              kpi1Divergence: emptySymbolKpi1,
              kpi1Trend: emptySymbolKpi1,

              kpi2Fact: emptySymbolKpi2,
              kpi2Plan: emptySymbolKpi2,
              kpi2Completion: emptySymbolKpi2,
              kpi2Trend: emptySymbolKpi2,
            }

            const subObjNotBrend = {
              // eslint-disable-next-line no-plusplus
              id: `stat-not-brend-${increment}`,
              system: 'Не бренд',
              consumptionFact: emptySymbolConsumption,
              consumptionPlan: emptySymbolConsumption,
              consumptionCompletion: emptySymbolConsumption,
              consumptionTrend: emptySymbolConsumption,

              kpi1Fact: emptySymbolKpi1,
              kpi1Plan: emptySymbolKpi1,
              kpi1Divergence: emptySymbolKpi1,
              kpi1Trend: emptySymbolKpi1,

              kpi2Fact: emptySymbolKpi2,
              kpi2Plan: emptySymbolKpi2,
              kpi2Completion: emptySymbolKpi2,
              kpi2Trend: emptySymbolKpi2,
            }

            statItem.forEach(item => {
              stat.system = item.system

              if (item.kpi === 'Расходы') {
                if (stat.consumptionFact === emptySymbol) emptySymbolConsumption = 0

                // this.columnsVisible.consumptionFact.display = true
                // this.columnsVisible.consumptionPlan.display = true
                // this.columnsVisible.consumptionCompletion.display = true
                // this.columnsVisible.consumptionTrend.display = true

                stat.consumptionFact += parseFloat(item.fact)
                stat.consumptionPlan = parseFloat(item.plan)
                stat.consumptionCompletion = parseFloat(item.completion)
                stat.consumptionTrend += parseFloat(item.trend)

                subObjBrend.consumptionFact += item.factBrend
                subObjBrend.consumptionPlan = item.planBrend
                subObjBrend.consumptionCompletion += item.completionBrend
                subObjBrend.consumptionTrend += item.trendBrend

                subObjNotBrend.consumptionFact += item.factNotBrend
                subObjNotBrend.consumptionPlan = item.planNotBrend
                subObjNotBrend.consumptionCompletion += item.completionNotBrend
                subObjNotBrend.consumptionTrend += item.trendNotBrend
              }

              if (this.availableKpi1Options.includes(item.kpi)) {
                if (stat.kpi1Fact === emptySymbol) emptySymbolKpi1 = 0

                this.columnsVisible.kpi1Fact.display = true
                this.columnsVisible.kpi1Plan.display = true
                this.columnsVisible.kpi1Divergence.display = true
                this.columnsVisible.kpi1Trend.display = true
                this.columnsVisible.kpi1Fact.tooltip = `${item.kpi} (факт)`
                this.columnsVisible.kpi1Plan.tooltip = `${item.kpi} (план)`
                this.columnsVisible.kpi1Divergence.tooltip = `${item.kpi} (расхождение в %)`
                this.columnsVisible.kpi1Trend.tooltip = `${item.kpi} (тренд)`
                stat.kpi1Fact += parseFloat(item.fact)
                stat.kpi1Plan = parseFloat(item.plan)
                stat.kpi1Divergence += parseFloat(item.divergence)
                stat.kpi1Trend = parseFloat(item.trend)
                subObjBrend.kpi1Fact += item.factBrend
                subObjBrend.kpi1Plan = item.planBrend
                subObjBrend.kpi1Divergence += item.divergenceBrend
                subObjBrend.kpi1Trend += item.trendBrend
                subObjNotBrend.kpi1Fact += item.factNotBrend
                subObjNotBrend.kpi1Plan = item.planNotBrend
                subObjNotBrend.kpi1Divergence += item.divergenceNotBrend
                subObjNotBrend.kpi1Trend += item.trendNotBrend
              }

              if (this.availableKpi2Options.includes(item.kpi)) {
                if (stat.kpi2Fact === emptySymbol) emptySymbolKpi2 = 0
                this.columnsVisible.kpi2Fact.display = true
                this.columnsVisible.kpi2Plan.display = true
                this.columnsVisible.kpi2Completion.display = true
                this.columnsVisible.kpi2Trend.display = true
                this.columnsVisible.kpi2Fact.tooltip = `${item.kpi} (факт)`
                this.columnsVisible.kpi2Plan.tooltip = `${item.kpi} (план)`
                this.columnsVisible.kpi2Completion.tooltip = `${item.kpi} (выполнение)`
                this.columnsVisible.kpi2Trend.tooltip = `${item.kpi} (тренд)`
                stat.kpi2Fact += parseFloat(item.fact)
                stat.kpi2Plan = parseFloat(item.plan)
                stat.kpi2Completion += parseFloat(item.completion)
                stat.kpi2Trend += parseFloat(item.trend)
                subObjBrend.kpi2Fact += item.factBrend
                subObjBrend.kpi2Plan = item.planBrend
                subObjBrend.kpi2Completion += item.completionBrend
                subObjBrend.kpi2Trend += item.trendBrend
                subObjNotBrend.kpi2Fact += item.factNotBrend
                subObjNotBrend.kpi2Plan = item.planNotBrend
                subObjNotBrend.kpi2Completion += item.completionNotBrend
                subObjNotBrend.kpi2Trend += item.trendNotBrend
              }
            })

            subObjBrend.kpi1Trend = subObjBrend.kpi2Trend !== 0 ? subObjBrend.consumptionTrend / subObjBrend.kpi2Trend : 0
            subObjNotBrend.kpi1Trend = subObjNotBrend.kpi2Trend !== 0 ? subObjNotBrend.consumptionTrend / subObjNotBrend.kpi2Trend : 0

            subObjNotBrend.consumptionPlan = '-'
            subObjNotBrend.consumptionCompletion = '-'
            subObjBrend.consumptionCompletion = '-'

            subObjNotBrend.kpi1Divergence = '-'
            subObjBrend.kpi1Divergence = '-'

            subObjBrend.consumptionPlan = '-'
            subObjBrend.kpi1Plan = '-'

            subObjNotBrend.kpi2Completion = '-'
            subObjBrend.kpi2Completion = '-'

            subObjBrend.kpi2Plan = '-'
            subObjNotBrend.kpi2Plan = '-'

            subObjNotBrend.kpi1Plan = '-'
            subObjBrend.kpi2Plan = '-'

            stat.items.push(subObjBrend)
            stat.items.push(subObjNotBrend)

            stat.kpi1Trend = stat.kpi2Trend !== 0 ? stat.consumptionTrend / stat.kpi2Trend : 0

            // eslint-disable-next-line no-plusplus
            stat.id = `stat${increment++}`
            this.data.push(stat)
            this.saveStatisticData.push(stat)

            //console.log(stat, 'stat')
          })

      /* let indexCampaign = 0
      this.data.map(c => c.campaign)
        .forEach(campaignText => {
          indexCampaign += 1
          this.campaignOptions.push({
            value: indexCampaign,
            text: campaignText,
          })
        }) */
      this.isLoading = false
    },
    calculateDependedFields(stat) {
      // eslint-disable-next-line radix

      stat.ctr_fact = stat.showsFact !== 0
          ? ((parseInt(stat.clicksFact) / parseInt(stat.showsFact)) * 100) : '-'

      stat.avgCpc_fact = stat.clicksFact !== 0
          ? ((parseFloat(stat.consumptionFact) / parseInt(stat.clicksFact))) : '-'

      // calculate Analytic values
      stat.analyticRoi_fact = stat.consumptionFact !== 0
          ? ((parseFloat(stat.analyticRevenueFact) - parseFloat(stat.consumptionFact))
          / parseFloat(stat.consumptionFact)) * 100 : '-'

      stat.analyticCr_fact = stat.clicksFact !== 0
          ? ((parseFloat(stat.analyticGoalsFact) / parseInt(stat.clicksFact)) * 100) : '-'

      stat.analyticCost_fact = stat.analyticGoalsFact !== 0
          ? ((parseFloat(stat.consumptionFact) / parseFloat(stat.analyticGoalsFact))) : '-'

      /* // calculate CRM values
      stat.roi = stat.consumption !== 0
        ? ((parseFloat(stat.profit) - parseFloat(stat.consumption)) / parseFloat(stat.consumption)) * 100 : '-'

      stat.cr = stat.clicks !== 0
        ? ((parseFloat(stat.numberOrders) / parseInt(stat.clicks)) * 100) : '-'

      stat.cost = stat.numberOrders !== 0
        ? ((parseFloat(stat.consumption) / parseFloat(stat.numberOrders))) : '-' */
    },
    buildTable(filterTableData, isDefaultRange) {
      this.$store.dispatch('statistic/fetchSummaryTableStatistic', filterTableData)
          .then(response => {
            // TO-DO: NEED refactoring
            const dataForParse = response.data
            if (dataForParse === 'NO_STATS') {
              this.emptyStats = dataForParse
            } else {
              if (isDefaultRange) {
                this.$store.commit('statistic/LOAD_SUMMARY_TABLE_DATA', dataForParse)
              }
              this.calculateTableData(dataForParse)
            }
          })
          .catch(() => {

          })
    },
    toggle(row) {
      this.$refs.table.toggleDetails(row)
    },
    // eslint-disable-next-line consistent-return
    transitionName() {
      if (this.useTransition) {
        return 'fade'
      }
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/tour.scss';
</style>
<style>
.flatpickr-input {
  width: 210px !important;
}

</style>
